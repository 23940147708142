@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  /* background-color: #243a52; */
  /* font-family: "Roboto Slab", serif; */
  /* overflow: hidden; */
  font-size: medium;
}

/**/

.container {
  margin: 0px auto 0 auto;
  height: 100vh;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* background-image: url(https://firebasestorage.googleapis.com/v0/b/mobilespacefun.appspot.com/o/newBG.png?alt=media&token=4b79db7c-14af-4b07-a1f5-db7a1d77fcf6); */
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 150%;
  transition: 1s;
  background-position-x: -00px;
  z-index: -20;
  /* border-radius: 40px; */
  /* box-shadow: 0px 0px 20px 6px #1f06232b; */
}
.nav-container {
  max-width: 500px;
  margin: 0px auto 0px 0px;
  padding: 0px;
}

.nav-bar {
  background-color: red;
  padding: 0px;
  margin: auto;
  height: 30px;
  transition: 1s;
}

.cover_container {
  /* background-color: gray; */
  width: 100%;
  height: 12vh;
  border-radius: 50px;
  display: flex;
  align-items: flex-end;
}

.home_body_container {
  /* background-color: #f3e6d6; */
  height: 97vh;
  width: 100%;
}

.body_main_buttons {
  width: 300px;
}

.main_buttons_container {
  position: relative;
}
.main_button_tab_container {
  /* border: 1px solid gray; */
  width: 30%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  z-index: 5;
}

.main_button {
  background-color: #60d1bc;
  width: 50px;
  height: 50px;
  margin: auto;
  text-align: center;
  border-radius: 50%;
  z-index: 5;
  vertical-align: middle;
  line-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#the_moving_drop_container {
  background-color: white;
  width: 54px;
  height: 74px;
  border-radius: 100px 100px 0px 0px;
  position: fixed;
  margin-top: -51.5px;
  margin-left: 50%;
  /* transition: 0.5s; */
  z-index: 2;
}

#moving_drop_left_part {
  position: absolute;
  background-color: #ff000000;
  left: -40px;
  bottom: 3px;
  width: 40px;
  height: 40px;
  z-index: 10;
  border-radius: 0px 0px 30px 0px;
  box-shadow: 14px 5px 0px white;
}

#moving_drop_right_part {
  position: absolute;
  background-color: #ff000000;
  right: -40px;
  bottom: 3px;
  width: 40px;
  height: 40px;
  z-index: 10;
  border-radius: 0px 0px 0px 30px;
  box-shadow: -14px 5px 0px white;
}

/*
@media only screen and (max-width: 300px) {
  .main_button{
    background-color: #60d1bc;
    width: 50px;
    height: 50px;
    margin: auto;
    text-align: center;
    border-radius: 50%;
  }
}
 */

.main_body_container {
  width: 100%;
  background-color: white;
  position: relative;
  top: 0px;
  height: 87vh;
  border-radius: 4px;
  box-shadow: 0px -10px 10px #0000005e;
  overflow: hidden;
}
.body_main_buttons {
  width: 250px;
  /* float: right; */
  /* position: relative; */
  /* left: 10%; */
}
.main_button {
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  cursor: pointer;
}

#button1 {
  color: white;
  line-height: 90px;
  vertical-align: middle;
  font-size: 30px;
  /* background-image: url(https://firebasestorage.googleapis.com/v0/b/mobilespacefun.appspot.com/o/icon1.png?alt=media&token=99f2b555-ea76-4c09-b263-05434b6a0ebc); */
}

#button2 {
  /* background-image: url(https://firebasestorage.googleapis.com/v0/b/mobilespacefun.appspot.com/o/icon2.png?alt=media&token=a11514bb-1cb0-499e-9f59-da6c035a55ce); */
}

#button3 {
  /* background-image: url(https://firebasestorage.googleapis.com/v0/b/mobilespacefun.appspot.com/o/icon3.png?alt=media&token=b7657d60-3afd-4daa-9250-07e22476a59b); */
}

#maincover_sub_title {
  font-size: small;
  font-weight: lighter;
  color: gray;
}

.home_body_grid_container {
  border: 1px solid gray;
  /* text-align: center; */
}

.home_body_grid_sub_container {
  border: 1px solid red;
  /* text-align: center; */
}
.white_card {
  background-color: white;
  line-height: 50%;
}

.elevated_card {
  /* line-height: 50%; */
  box-shadow: 0px 9px 20px 0px #00000024;
}

.flat_card {
  box-shadow: none;
  border: 1px solid gray;
}
.sm_dark_card {
  background-color: #243a52;
  color: white;
  vertical-align: middle;
  line-height: 50%;
}
.transparent {
  background-color: none;
}

.tiny_radius {
  border-radius: 5px;
}

.small_radius {
  border-radius: 10px;
}

.large_radius {
  border-radius: 20px;
}

.top_radius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.no_radius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.bottom_radius {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.fit_content {
  width: fit-content;
}

.small_padding {
  padding: 0px 10px;
}

.no_padding {
  padding: 0px 0px;
}
.small_icons {
  font-size: small;
}
.medium_icons {
  font-size: medium;
}
.large_icons {
  font-size: x-large;
}

.xx_large_icons {
  font-size: xxx-large;
  /* margin-top: 0.3em; */
}
.sm_small_text {
  font-size: small;
}

.sm_tiny_text {
  font-size: xx-small;
}

.sm_medium_text {
  font-size: medium;
}

.right_float {
  float: right;
}

.divider_vertical_mainColor {
  background-color: #243a52;
  width: 4px;
  /* margin: 0px 6px; */
  border-radius: 2px;
}

.homePage_card_title_bold {
  font-weight: bold;
}

.card_1_height {
  height: 70px;
}
.card_2_height {
  height: 70px;
}

.card_3_height {
  height: 70px;
  /* transition: 0.4s; */
}

.center {
  text-align: center;
  vertical-align: middle;
  margin: auto;
}
.card_middle {
  vertical-align: middle;
  display: table-cell;
  margin: auto;
  width: 100%;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.bottom {
  align-self: flex-end;
  padding-bottom: 10px;
}

.middle {
  align-self: center;
  padding: 0px;
}

div#WorkSpace_page {
  margin-left: 0px;
  transition: 0.5s;
}

.middle_center_card {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#overlayCard {
  /* background-color: #382339d9; */
  background-color: #384b60bf;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 12;
}

div#overlayCard_body {
  width: 90%;
  max-width: 400px;
  background-color: #ffffff;
  transition: 1s;
  min-height: 200px;
  border-radius: 30px;
  box-shadow: 0px 10px 20px #26162845;
}
.blurrable {
  -webkit-filter: blur(0px);
}

div#overlayCard_header {
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  flex-direction: row;
}

div#overlay_card_header_title {
  width: 57%;
  display: flex;
  justify-content: flex-start;
  padding: 0px 11px;
}

div#overlay_card_body_continer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}
div#overlay_card_body_body {
  width: 90%;
  /* height: 200px; */
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  /* margin: 28px auto; */
  /* padding: 24px 0px; */
}
div#listing-image_preview {
  width: 100%;
  background-color: gray;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/mobilespacefun.appspot.com/o/no_listing_img.png?alt=media&token=507ea5ed-f19c-450a-a91b-fd593cc2ef50);
  height: 177px;
  background-position: center;
  border-radius: 20px;
}

/* #card_image_cover {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  background-position: center;
  background-size: cover;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-end;
} */

#card_image_cover {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e7e7e9;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-end;
}

div#card_Image_title {
  margin: 10px 20px;
  font-size: small;
}

.column_card_orientation {
  display: flex;
  /* border: 1px solid black; */
  align-items: stretch;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: column;
}

#Dashboard_page {
  margin-left: 6000px;
  transition: 0.5s;
  position: absolute;
  top: 30px;
  overflow: scroll;
  height: 90%;
  width: 100%;
  /* background-color: red; */
}

#wx_page {
  margin-left: 3000px;
  transition: 0.5s;
  position: absolute;
  top: 0px;
  overflow: scroll;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: stretch;
  align-items: flex-start;
}
div#image_card_image_content {
  /* width: 100px; */
  height: 100%;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: stretch;
}

div#image_card_image_container {
  overflow: hidden;
  height: 100%;
  border-radius: 21px 0px 0px 21px;
}

span#skeleton_box {
  height: 100%;
}

/* div#image_card_conatiner {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
} */

div#image_card_body_conatiner {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

#list_image_card_title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px 7px;
  max-width: 250px;
}
div#subtitle {
  text-align: left;
  font-size: x-small;
  color: #ed6a40;
  text-overflow: ellipsis;
  max-width: 239px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.5em;
}

div#workspace_card_image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

img.spacematch_login_logo {
  /* background-color: white; */
  width: 30%;
  margin: 18%;
  /* text-align: center; */
  /* height: 40px; */
}

div#loginPage {
  width: 89%;
  height: 70%;
  max-width: 500px;
  display: flex;
  background-color: #ffffff;
  align-content: center;
  justify-content: space-around;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
  border-radius: 26px;
  padding: 10% 0px;
}

.main_login_form_page {
  /* border-radius: 40px; */
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.textfield {
}

div#login_form_container {
  width: 73%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  align-items: center;
}

div#icon_row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: x-large;
}

svg.button_icon {
  border: 1px solid;
  padding: 2%;
  border-radius: 32%;
  /* background-color: #ed6a40; */
  /* color: #ed6a40; */
  cursor: pointer;
  box-shadow: 0px 0px 7px 2px black;
  /* box-sizing: border-box; */
  -moz-box-sizing: border-box;
  /* -webkit-box-sizing: border-box; */
  transition: 0.2s;
}

.button_icon:hover {
  box-shadow: none;
  border: 1px solid #f4a762;
}

#signin_signup_button {
  margin: 10% auto;
}

.custom_errors {
  color: red;
}

.page_title {
  font-weight: bold;
  font-size: large;
  color: #243a52;
}

div#card_image_iconlist {
  padding: 4px;
  width: 45%;
  display: flex;
  justify-content: space-evenly;
}

span#sk_dummytext {
  margin-left: 10px;
}

.card_image_iconlist_icon {
  /* background-color: red; */
  padding: 3px;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid #f4e5d3;
  color: #ed6a40;
  cursor: pointer;
}

.card_image_iconlist_icon:hover {
  /* background-color: red; */
  padding: 3px;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid #ed6a40;
  color: #ed6a40;
  cursor: pointer;
}

.imagecard_card_body_container {
  display: flex;
  align-content: stretch;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  align-items: stretch;
  width: 70%;
}

div#profile_badge_image {
  width: 35px;
  height: 37px;
  /* margin-right: 0px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9px;
  background-color: #c5c5c5;
  border: 1px solid #f3e2d2;
  box-sizing: border-box;
  box-shadow: 0px 0px 17px black;
}

div#card_image_iconlist {
  padding: 4px;
  width: 77%;
  display: flex;
  justify-content: space-evenly;
  font-size: small;
  align-items: flex-end;
  align-content: center;
}

div#profile_badge_container {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  flex-direction: column;
}
#maincover_title {
  color: white;
  font-size: large;
  font-weight: bold;
  text-align: left;
  width: 57%;
  padding: 5%;
}

/* div#profile_badge {
  width: 32%;
  max-width: 150px; */
/* background-color: white; */
/* display: flex; */
/* height: 74px; */
/* color: #f4e3d3; */
/* align-items: center; */
/* flex-direction: column; */
/* padding: 10px 0px;
  justify-content: space-evenly; */
/* } */
div#profile_badge_name {
  color: white;
  font-size: small;
}

div#editProfilePicture {
  /* background-color: red; */
  width: 46px;
  height: 46px;
  /* position: relative; */
  /* right: 0px; */
  /* bottom: 0px; */
  /* margin-left: 27px; */
  /* margin-top: 10px; */
  color: white;
}

.edit_profile_settings {
  color: white;
  font-size: medium;
  display: flex;
  align-content: space-around;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  padding: 4px;
  margin: 2px;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
  background-color: #f4e3d338;
}
.edit_profile_settings:hover {
  background-color: #ed6a404f;
}

div#profile_settings_icons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: flex-end;
}

button#mic_button {
  font-size: 310%;
}

span#sm_icon_svg {
  float: right;
  width: 20px;
  height: 20px;
  /* text-shadow: 0px 0px 3px black; */
}

.publish_lisitng_label {
  font-size: xx-small;
}

.domcolContainer {
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
  align-content: center;
}

.domcol {
  width: 20%;
  height: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: #ffffff00;
}

div#domoinant_color_tag {
  background-color: red;
  height: 6%;
  margin: 0px 15px;
  border-radius: 5px;
  color: #ffffff00;
  font-size: xx-small;
}

div#listing_Editor_page_container {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

div#navbar_cover {
  background-color: #334a62;
}

.back_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

div#edit_listing_body {
  /* background-color: red; */
  height: 81%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  overflow: scroll;
}

.editlisting_image {
  width: 100%;
  color: #ffffff00;
  height: 200px;
  background-position: center;
  background-size: cover;
}

div#daytimepicker_container {
  width: 100%;
  height: 100%;
  /* background-color: white; */
}

div#daytimepicker_container2 {
  width: 100%;
  height: 100%;
  /* background-color: white; */
}
div#daytimePicker_row {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}

div#dayName {
  padding: 2px;
  background-color: #ed6a40;
  width: 13%;
  margin: 2px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: small;
  min-width: 38px;
}
div#dayDate {
  font-size: small;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: #382339;
  padding: 2px;
  width: 85px;
  color: white;
  border-radius: 5px;
  justify-content: center;
}

div#from_time_select {
  width: 20%;
  font-size: small;
  /* background-color: #e6e6e6; */
  display: flex;
  justify-content: center;
  border-radius: 25px;
  align-items: center;
}

div#checkBoxes {
  width: 60%;
  height: 17px;
  display: flex;
  justify-content: space-evenly;
  padding: 2px;
  background-color: #f3e6d6;
  border-radius: 27px;
  overflow: hidden;
  align-items: center;
}

div#listing_description_box {
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

div#dom_color_bar {
  width: 9%;
  border-radius: 2px;
}

div#dom_color_bar_container {
  display: flex;
  color: #ffffff00;
  padding: 7px 0px;
  justify-content: space-around;
}

/* .editlisting_image {
  width: 100%;
  color: #ffffff00;
  height: 200px;
  background-position: center;
  border-radius: 20px;
  background-size: cover;
  box-shadow: 0px 0px 18px #000000ab;
} */

div#data_visualizer_container {
  width: 100%;
  height: 100%;
  background-color: white;
}

div#data_vis_canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

text#data_vis_text {
  font-size: xx-small;
  position: absolute;
  top: 21px;
  margin-top: 27px;
}

div#start_posting_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
}
div#start_posting_profile_pic_container {
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 100px; */
}
div#start_posting_profile_pic_thumbnail {
  height: 30px;
  width: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid #f4e5d3;
}
div#start_posting_textfield_area_container {
  width: 80%;
  height: 100%;
  background-color: #f9f1e7;
  /* border: 1px solid #c7bdb0; */
  /* border-radius: 10px 0px 0px 10px; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: gray;
  flex-direction: column;
}
div#start_posting_submit_continer {
  width: 10%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #38203b;
  color: white;
  border-radius: 0px 15px 15px 0px;
  align-items: center;
}

#posts_divider {
  width: 100%;
  height: 1px;
  background-color: #371f3b;
  border: none;
  box-shadow: 0px 1px 0px white;
}

div#posts_body {
  color: #371f3b7a;
  text-shadow: 0px 1px 0px white;
}

div#posting_card_social_wx {
  /* border: 1px solid #d6ccc0; */
  box-shadow: none;
  background-color: #38203b;
}

div#new_post_textfiled_box {
  height: 65%;
  /* margin: 6px; */
  width: 100%;
  display: flex;
  align-items: center;
}
#new_post_icons_container {
  height: 35%;
  width: 169px;
  display: flex;
  overflow: hidden;
}
div#new_post_icons {
  width: 117px;
  display: flex;
  border-radius: 0px 0px 8px 0px;
  align-items: center;
  background-color: #ed6a40;
  color: white;
  justify-content: space-around;
}

div#box_inside_the_box {
  /* box-sizing: border-box; */
  border-bottom: 1px solid #f5c7b8;
  box-shadow: inset 0px -1px 0px #b55333;
  width: 100%;
  margin: 0px;
  height: 100%;
  padding: 0px 7px;
  font-size: small;
  display: flex;
  background-color: white;
  align-items: center;
  cursor: pointer;
}
div#new_post_icons_icons_nudge {
  color: #ffffff00;
  width: 30px;
  background-color: #ffffff00;
  border-radius: 10px;
  box-shadow: -10px -10px 0px #ed6b40;
}

.day_cell {
  font-size: x-small;
  height: 20px;
  background-color: #243a52;
  width: 20%;
  padding: 0px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 2px;
  color: white;
}
div#day_row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.hour_cell {
  font-size: xx-small;
  /* background-color: #d46969; */
  width: 4.18%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 20px;
  cursor: pointer;
  flex-direction: column;
}

div#hours_row {
  width: 80%;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #a5a5a5;
  color: #243a52;
}

.tick {
  font-size: 5px;
}

.tick.h0,
.tick.h3,
.tick.h6,
.tick.h9,
.tick.h12,
.tick.h15,
.tick.h18,
.tick.h21 {
  font-size: 6px;
  font-weight: bold;
  color: #ed6a40;
  background-color: #ed6a40;
  width: 4px;
  border-radius: 11px;
}

.mapboxgl-canvas {
  width: "100%";
}

div#workspace_eidts_bar_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.fullScreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background-color: white;
}

/* div#Drawer_addFloorPlan {
  position: fixed;
  width: 20%;
  height: 100%;
  background-color: #243a52;
  left: 0px;
  top: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
} */

div#addFloorPlan_body {
  width: 80%;
  position: fixed;
  left: 20%;
  height: 100%;
  background-color: white;
  overflow: scroll;
}

div#addFloorPlan_side_bar_container {
  color: white;
  width: 93%;
  height: 95%;
}

div#addFloor_profile_badge {
  display: flex;
  align-items: center;
  /* margin: 0px 10%; */
  /* background-color: #53395463; */
  flex-direction: column;
  padding: 15px;
  border-radius: 20px;
}

div#profile_badge {
  width: 120px;
  /* max-width: 150px; */
  /* background-color: white; */
  display: flex;
  /* height: 74px; */
  /* color: #f4e3d3; */
  align-items: center;
  /* flex-direction: column; */
  /* padding: 10px 0px; */
  justify-content: space-around;
}

/* hr#add_floor_divider {
  margin: 5px;
  background-color: #160417;
  border: none;
  height: 1px;
  box-shadow: 0px 1px 0px #5f3b61;
} */

hr#add_floor_divider {
  margin: 5px;
  background-color: #0f1e31;
  border: none;
  height: 1px;
  box-shadow: 0px 1px 0px #4f6684;
}
div#app_name {
  font-weight: bold;
  font-size: 1.2em;
  line-height: 2em;
}

div#add_new_level_container {
  padding: 15px;
}

div#geojson_fileINput_container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid gainsboro;
  height: 35px;
}

div#floorPlanEditor_add_new_item_containr {
  display: flex;
  justify-content: center;
}

div#add_new_level_container {
  padding: 15px;
  width: 800px;
}

div#spatialDataUrl {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}

div#edit_floor_data_row {
  display: flex;
  justify-content: flex-start;
  height: 49px;
  /* border-bottom: 1px solid #e6e6e6; */
  align-items: flex-end;
  align-content: stretch;
  flex-wrap: wrap;
  flex-direction: column;
}

div#Drawer_addFloorPlan {
  position: fixed;
  width: 20%;
  height: 100%;
  background-color: #394a60;
  left: 0px;
  top: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

div#addFloorPlan_body_contents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: scroll;
}

div#MapPreview {
  background-color: #b2e9ff;
  /* height: 500px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center; */
}

.host_basic_card {
  background-color: white;
  border-radius: 30px;
  /* min-height: 100px; */
  box-shadow: 0px 0px 26px #5656563d;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
}

#card_title {
  width: 100%;
  background-color: #253a52;
  color: white;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
  font-weight: bold;
}

#card_body {
  width: 100%;
  height: 100%;
}

div#listing_img {
  width: 40px;
  height: 40px;
  margin: 4px;
  border-radius: 12px;
  background-size: cover;
  display: flex;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
}

div#listing_container {
  display: flex;
  width: 100%;
  align-items: center;
}

div#host_user_profile_pic {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

div#host_user_profile_handle {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

div#hi_name {
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: "Roboto Slab", serif; */
  font-size: 2vw;
  height: 50%;
  padding: 0px 20px;
}
.image_cover {
  display: flex;
  align-items: flex-start;
  color: #384b60;
  justify-content: flex-end;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

a#gella {
  text-decoration: none;
}

a#gella:hover {
  border: 1px solid red;
}

.hoverable {
  /* border: 1px solid #ffd54f; */
  border-radius: 29px;
  transition: 0.2s;
  cursor: pointer;
}

.hoverable:hover {
  border: 1px solid #00bcd470;
  border-radius: 29px;
  box-shadow: 0px 0px 10px #00bcd440;
}

div#main_body_container {
  height: 100%;
}

div#somethign_gellawy {
  height: 100%;
  background-color: red;
  display: flex;
  justify-content: center;
}

.host_main_card {
  background-color: white;
  /* margin: 20px; */
  /* border-radius: 28px; */
  /* box-shadow: 0px 0px 26px #5656563d; */
  display: flex;
  /* border: none; */
  align-items: stretch;
}

/* .card_container {
  margin: 20px;
  
  width: 100%;
  display: flex;
  box-shadow: 0px 0px 26px #5656563d;
  border-radius: 30px;
} */

.card_container {
  margin: 10px;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  box-shadow: 0px 0px 10px #5656562b;
  /* height: 100%; */
  border-radius: 30px;
  overflow: hidden;
}

.host_card_body {
  /* background-color: red; */
  /* padding: 20px; */
  width: 100%;
  /* display: flex; */
  /* align-items: center; */
  height: 100%;
}

#hollow_block {
  height: 0px;
  width: 100vw;
}

.dark {
  background-color: #0f1e31;
}

.flat {
  box-shadow: none;
}

.secondary {
  background-color: #ffd54f;
}
/* 
div#buttons_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
} */

div#buttons_container {
  height: 100%;
  /* background-color: red; */
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

/* div#Button_div {
  height: 23%;

  display: flex;
  font-size: large;
  padding: 0px 30px;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
} */
div#Button_div {
  height: 2.5em;
  /* background-color: blue; */
  display: flex;
  font-size: large;
  padding: 0px 30px;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}
.selected {
  color: #253a52;
  background-color: #ffcb05;
  font-weight: bold;
  transition: 0.2s;
}

/* .first {
  border-radius: 20px 20px 0px 0px;
} */

/* .last {
  border-radius: 0px 0px 20px 20px;
} */

div#Button_div:hover {
  background-color: #ffefb1;
  transition: 0.2s;
  cursor: pointer;
}

.selected:hover {
  background-color: #ffcb05;
  transition: 0.2s;
}

div#card_bottom_text_container {
  /* background-color: red; */
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-shadow: 0px 0px 3px white;
}

div#card_bottom_text_body {
  padding: 5px;
  background-color: #ffffffc7;
  display: flex;
  width: 100%;
  font-weight: bold;
  border-radius: 0px 0px 30px 30px;
  justify-content: center;
}

div#card_middle_text_container {
  /* background-color: red; */
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #ffffffe0;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  text-shadow: 0px 0px 3px white;
}

div#card_middle_text_body {
  padding: 17px;
  background-color: #ffffff70;
  /* display: flex; */
  /* width: 100%; */
  /* height: 100%; */
  font-weight: bold;
  /* border: 1px solid; */
  border-radius: 30px;
  justify-content: center;
  align-items: center;
}

.listings_card_body {
  background-color: red;
  width: 100%;
  padding: 20px;
}

.only_mobile {
  display: none;
}

div#sk_listings {
  display: flex;
  justify-content: space-between;
  margin: 11px;
}
.inputRoot {
  width: 85%;
  /* height: 100%; */
  background-color: #ffffff9e;
  border: 3px solid #d4d4d4;
  padding: 7px;
  padding-left: 54px;
  border-radius: 20px;
}

.searchIcon {
  padding: 13px;
  /* height: 100%; */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBar_notifications_container {
  /* display: flex; */
  width: 100%;
  /* padding: 24px; */
  height: 50%;
  align-items: center;
}

div#searchbar_container {
  border-radius: 20px 20px 0px 0px;
  padding: 0px 18px;
  /* height: 77px; */
  height: 100%;
  display: flex;
  /* width: 100%; */
  /* background-color: #263a52; */
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}

div#logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

div#logo {
  height: 164px;
  width: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-weight: bold;
  color: #a9bbde;
  /* text-shadow: 0px 0px 2px #ffcb01; */
}

svg.linkIcon {
  margin: 0px 6px;
  font-size: x-large;
  color: #233b53a1;
}

div#colorScheme {
  /* width: 22px;
  margin: 5px;
  height: 40px;
  background-color: red;
  border-radius: 32px 0px 0px 30px; */
}

.slack_team_container {
  background-color: #fff;
  margin: 10px;
  display: flex;
  align-items: center;
  width: 26%;
  height: 48px;
  border-radius: 31px;
  float: left;
  box-shadow: 0 0 20px rgb(0 45 78 / 15%);
}

.slack_user_name {
  margin: 0px 13px;
}

.slack_page_user_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  body {
    font-size: small;
  }
  .only_desktop {
    display: none;
  }
  .only_mobile {
    display: block;
  }

  div#hi_name {
    /* font-family: 'Poppins', sans-serif; */
    /* font-family: "Roboto Slab", serif; */
    font-size: 5vw;
  }

  .card_container {
    margin: 5px;
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    box-shadow: 0px 0px 4px #5656563d;
    /* height: 100%; */
    border-radius: 5px;
    overflow: hidden;
  }

  div#main_body_container {
    height: 100%;
    margin-top: 64px;
  }

  div#Button_div {
    font-size: medium;
  }
}

div#listings_body {
  /* background-color: red; */
  width: 100%;
  padding: 20px;
}

.slidercontainer {
  padding: 30px 10px 0;
  height: 70px;
  width: 90%;
  overflow: hidden;
  box-sizing: border-box;
}

.react_time_range__tick_label {
  color: "red";
  font-size: 8px;
}
